<template>
  <v-layout
    align-center
    justify-center
    fill-height
    class="pt-5 pb-5 box page-login fill-height"
  >
    <div class="flex-me elevation-12">
      <v-card
        class="fill-height d-flex flex-column justify-space-around pa-2 card-1 card-combo"
      >
        <v-card-title primary-title class="justify-left">
          <div class="text-left card-1-title">
            <h2 class>A Simple, Powerful Trading And</h2>
            <h2 class>Logistics Platform</h2>
            <br />
            <h6 class="card-1-sub">
              Nereus.io provides a simple, powerful trading and logistics platform for the
              industry to buy, move,
            </h6>
            <h6 class="card-1-sub">track and sell maritime assets.</h6>
          </div>
        </v-card-title>
      </v-card>
      <ValidationObserver v-slot="{ invalid }">
        <v-card
          class="fill-height d-flex flex-column justify-space-around pa-2 card-2 card-combo"
          light
        >
          <v-card-title primary-title class="justify-center">
            <div class="text-center">
              <slot name="text-header"></slot>
            </div>
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <v-form class="form text-end" @submit.prevent="submit" id="login-form">
              <ValidationProvider
                rules="required|email"
                name="Email"
                validate-on="change"
                v-slot="{ errors, valid }"
                v-if="login || forgot_password"
                :custom-messages="{ required: 'The Email field is required' }"
              >
                <v-text-field
                  data-cy="email"
                  id="email"
                  label="Email"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="credentials.email"
                  :error-messages="errors[0]"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="Password"
                v-slot="{ errors }"
                rules="required|min:8"
                vid="confirm_password"
                v-if="login || reset_password"
                :custom-messages="{
                  required: 'The field is required',
                  min: 'The Password field must be at least 8 characters',
                }"
              >
                <v-text-field
                  id="password"
                  data-cy="password"
                  label="Password"
                  v-model="credentials.password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :error-messages="reset_password ? errors[0] : ''"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                name="Confirm Password"
                v-slot="{ errors }"
                rules="required|confirmed:confirm_password"
                v-if="reset_password"
                :custom-messages="{
                  required: 'The field is required',
                  confirmed: 'The passsword field confirmation does not match',
                }"
              >
                <v-text-field
                  id="confirm_password"
                  label="Confirm Password"
                  v-model="credentials.confirm_password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :error-messages="errors[0]"
                ></v-text-field>
              </ValidationProvider>

              <router-link
                v-if="login"
                :to="{
                  path: '/forgot-password',
                }"
              >
                Forgot password?</router-link
              >
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              id="btn_login"
              data-cy="btn_login"
              type="submit"
              color="primary"
              block
              :loading="loading"
              form="login-form"
              :disabled="invalid"
              >{{ buttonName }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </div>
  </v-layout>
</template>

<script>
import { extend } from "vee-validate";
import { required, email, confirmed, min } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver } from "vee-validate";
extend("required", required);
extend("email", email);
extend("min", min);
extend("confirmed", confirmed);

export default {
  props: ["loading", "forgot_password", "reset_password", "login"],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      credentials: {
        email: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
    buttonName() {
      return this.login ? "Login" : "Send";
    },
  },
  mounted() {
    this.loading = false;
    this.$store.commit("setSpinnerShow", false);
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.$emit("login", this.credentials);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1036px) {
  .card-1 {
    display: none !important;
  }
}

.page-login {
  background: #0000008a;
}

.card-1-title {
  width: 700px;
  padding-left: 2em;
}

.card-1-sub {
  font-weight: 400;
  line-height: initial;
}

.card-1 {
  background-size: cover;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  width: 50em;
  background-image: url(https://app.nereus.io/_lib/libraries/grp/nereus/container-scaled-nereus.jpg);
}

.card-2 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  background: white;
  width: 25em;
}

.scale-1-2 {
  transform: scale(1.2);
}

.flex-me {
  height: 35em;
  display: flex;
  flex-flow: row;
}

.form {
  width: 100%;
}

.form >>> input {
  padding: 10px;
}
</style>
